import {
  AdjustInventory,
  AdjustInventoryForm,
  AdjustInventoryEditForm,
} from "./AdjustInventory/index.js";
import { CreateCatalog } from "./Catalog/Create.js";
import { UpdateCatalog } from "./Catalog/Update.js";
import { Catalog } from "./Catalog/index.js";
import { PrintPdfCategory } from "./Categories/PrintPdf.js";
import Categories, {
  CreateCategory,
  UpdateCategory,
} from "./Categories/index.js";
import { CouponsCreateForm } from "./Coupons/create.js";
import { Coupons } from "./Coupons/index.js";
import { CouponsUpdateForm } from "./Coupons/update.js";
import { CreateCustomAttribute } from "./CustomAttributes/Create.js";
import { UpdateCustomAttribute } from "./CustomAttributes/Update.js";
import { CustomAttributes } from "./CustomAttributes/index.js";
import { PrintPdfDepartment } from "./Departments/PrintPdf.js";
import Departments, {
  CreateDepartment,
  UpdateDepartment,
} from "./Departments/index.js";
import DiscountScene, {
  CreateDiscount,
  UpdateDiscount,
} from "./Discounts/index.js";
import { CreateInventoryTransfer } from "./InventoryTransfer/create.js";
import { InventoryTransfer } from "./InventoryTransfer/index.js";
import { EditInventoryTransfer } from "./InventoryTransfer/update.js";
import MoveVariantForm from "./ItemList/ConvertVariantToItem.js";
import { CreateVariant } from "./ItemList/Form/Variant/Create.js";
import { UpdateVariant } from "./ItemList/Form/Variant/Update.js";
import { ItemPreview } from "./ItemList/Preview/index.js";
import ItemList, {
  CreateProduct,
  UpdateProduct,
  PrintLabel,
} from "./ItemList/index.js";
import PricingHistory from "./ItemList/pricing-history.js";
import StockHistory from "./ItemList/stock-history.js";
import { ItemListReorders } from "./ItemSkuReorders/index.js";
import NewlyAddedItemsScene from "./NewArrivals/index.js";
import OutOfStockScene from "./OutOfStock/index.js";
import { CreatePromotion } from "./Promotions/CreatePromotion.js";
import { UpdatePromotion } from "./Promotions/UpdatePromotion.js";
import { Promotions } from "./Promotions/index.js";
import { CreateRateCost, EditRateCost, RateCost } from "./RateCost/index.js";
import { PrintPdfSeason } from "./Seasons/PrintPdf.js";
import Seasons, { CreateSeason, UpdateSeason } from "./Seasons/index.js";
import { SuggestedItemsPdf } from "./SuggestedItems/SuggestedItemsPdf.js";
import { SuggestedItems, CreateSuggestedItem } from "./SuggestedItems/index.js";
import { Tags } from "./Tags/index.js";
import { UpdatePoNewItems } from "./UpcomingItems/CreatePO/NewItems/Edit.js";
import { CreatePoNewItems } from "./UpcomingItems/CreatePO/NewItems/NewItems.js";
import { UpdatePoRestock } from "./UpcomingItems/CreatePO/Restock/Edit.js";
import { CreatePoRestock } from "./UpcomingItems/CreatePO/Restock/index.js";
import { ReceiveItems } from "./UpcomingItems/ReceiveItems/ReceiveItems.js";
import { ReceiveItemsWaitingList } from "./UpcomingItems/ReceiveItems/ReceiveItemsWaitingList.js";
import { UpcomingItems } from "./UpcomingItems/index.js";
import VendorsScene, { CreateVendor, EditVendor } from "./Vendors/index.js";
import AcademyWaitingList from "./WaitingList/WaitingListAcademy/index.js";
import { CreateWaitingList } from "./WaitingList/WaitingListProducts/CreateWaitingList.js";
import WaitingListProductsScene from "./WaitingList/WaitingListProducts/WaitingList.js";
import WaitingListChooseScene from "./WaitingList/waitingListChoose.js";
import InventoryScene from "./index.jsx";
import SuppliersTable from "./suppliers/index.js";
import { CreateSupplier } from "./suppliers/createSupplier.js";
import { UpdateSupplier } from "./suppliers/updateSupplier.js";
import { PurchaseOrderInvoice } from "./UpcomingItems/ReceiveItems/Invoice.js";
import { PrintTransferInventoryPdf } from "./InventoryTransfer/print-pdf.js";
import { SuggestedDisabledItemsScene } from "./OutOfStock/SuggestedDisabledItems/index.js";

export const inventoryRoutes = [
  {
    index: true,
    element: <InventoryScene />,
    breadcrumb: "Inventory",
  },
  {
    path: "vendors",
    element: <VendorsScene />,
    breadcrumb: "Vendors",
  },
  {
    path: "vendors/create",
    element: <CreateVendor />,
    breadcrumb: "Vendor: new",
  },
  {
    path: "vendors/edit/:id",
    element: <EditVendor />,
    breadcrumb: "Vendor: edit",
    skipIndex: true,
  },
  {
    path: "item-list",
    element: <ItemList />,
    breadcrumb: "Item list",
  },
  {
    path: "item-list/movement-history/:id",
    element: <StockHistory />,
    breadcrumb: "Stock history",
  },
  {
    path: "item-list/pricing-history/:id",
    element: <PricingHistory />,
    breadcrumb: "Price history",
  },
  {
    path: "item-list/create",
    element: <CreateProduct />,
    breadcrumb: "Product: new",
  },
  {
    path: "item-list/edit/:id",
    element: <UpdateProduct />,
    breadcrumb: "Product: edit",
  },
  {
    path: "item-list/create/convert",
    element: <MoveVariantForm />,
    breadcrumb: "Product: convert variant to item",
  },
  {
    path: "item-list/edit/:id/variant/create",
    element: <CreateVariant />,
    breadcrumb: "Create variant",
  },
  {
    path: "item-list/edit/:id/variant/edit/:itemSkuId",
    element: <UpdateVariant />,
    breadcrumb: "Edit variant",
  },
  {
    path: "item-list/preview/:id",
    element: <ItemPreview />,
    breadcrumb: "Product: preview",
  },
  {
    path: "item-list/print-label",
    element: <PrintLabel />,
    breadcrumb: "Print label",
  },
  {
    path: "categories",
    element: <Categories />,
    breadcrumb: "Categories",
  },
  {
    path: "categories/create",
    element: <CreateCategory />,
    breadcrumb: "Category: new",
  },
  {
    path: "categories/edit/:id",
    element: <UpdateCategory />,
    breadcrumb: "Category: edit",
    skipIndex: true,
  },
  {
    path: "new-arrivals",
    element: <NewlyAddedItemsScene />,
    breadcrumb: "New arrivals",
  },
  {
    path: "departments",
    element: <Departments />,
    breadcrumb: "Departments",
  },
  {
    path: "departments/create",
    element: <CreateDepartment />,
    breadcrumb: "Department: new",
    skipIndex: true,
  },
  {
    path: "departments/edit/:id",
    element: <UpdateDepartment />,
    breadcrumb: "Department: edit",
    skipIndex: true,
  },
  {
    path: "discounts",
    element: <DiscountScene />,
    breadcrumb: "Discounts",
  },
  {
    path: "discounts/create",
    element: <CreateDiscount />,
    breadcrumb: "Discount: new",
    skipIndex: true,
  },
  {
    path: "discounts/edit/:reference",
    breadcrumb: "Discount: edit",
    skipIndex: true,
    element: <UpdateDiscount />,
  },
  {
    path: "coupons",
    breadcrumb: "Coupons",
    element: <Coupons />,
  },
  {
    path: "coupons/create",
    breadcrumb: "Coupons: new",
    skipIndex: true,
    element: <CouponsCreateForm />,
  },
  {
    path: "coupons/edit/:reference",
    breadcrumb: "Coupons: edit",
    skipIndex: true,
    element: <CouponsUpdateForm />,
  },
  {
    path: "promotions",
    element: <Promotions />,
    breadcrumb: "Promotions",
  },
  {
    path: "promotions/create",
    element: <CreatePromotion />,
    breadcrumb: "Promotions: new",
  },
  {
    path: "promotions/edit/:id",
    element: <UpdatePromotion />,
    breadcrumb: "Promotions: Edit",
  },
  {
    path: "seasons",
    element: <Seasons />,
    breadcrumb: "Seasons",
  },
  {
    path: "seasons/create",
    element: <CreateSeason />,
    breadcrumb: "Season: new",
    skipIndex: true,
  },
  {
    path: "seasons/edit/:id",
    element: <UpdateSeason />,
    breadcrumb: "Season: edit",
    skipIndex: true,
  },
  {
    path: "waiting-list",
    element: <WaitingListChooseScene />,
    breadcrumb: "Waiting list",
  },
  {
    path: "waiting-list/academy",
    element: <AcademyWaitingList />,
    breadcrumb: "Academy",
  },
  {
    path: "waiting-list/products",
    element: <WaitingListProductsScene />,
    breadcrumb: "Products",
  },
  {
    path: "waiting-list/products/create",
    element: <CreateWaitingList />,
    breadcrumb: "Waiting list: new",
  },
  {
    path: "out-of-stock",
    element: <OutOfStockScene />,
    breadcrumb: "Out of stock",
  },
  {
    path: "out-of-stock/suggested-disable-items",
    element: <SuggestedDisabledItemsScene />,
    breadcrumb: "Out of stock: suggested disabled items",
  },
  {
    path: "reorder-point",
    element: <ItemListReorders />,
    breadcrumb: "Reorder point",
  },
  {
    path: "suggested-items",
    element: <SuggestedItems />,
    breadcrumb: "Suggested items",
  },
  {
    path: "suggested-items/create",
    element: <CreateSuggestedItem />,
    breadcrumb: "Suggested item: new",
  },
  {
    path: "upcoming-items",
    element: <UpcomingItems />,
    breadcrumb: "Upcoming items",
  },
  {
    path: "upcoming-items/create-po/new-items",
    element: <CreatePoNewItems />,
    breadcrumb: "Create PO: New items",
  },
  {
    path: "upcoming-items/create-po/new-items/:poId",
    element: <UpdatePoNewItems />,
    breadcrumb: "Edit PO: New items",
  },
  {
    path: "upcoming-items/create-po/restock",
    element: <CreatePoRestock />,
    breadcrumb: "Create PO: Restock",
  },
  {
    path: "upcoming-items/create-po/restock/:poId",
    element: <UpdatePoRestock />,
    breadcrumb: "Edit PO: Restock",
  },
  {
    path: "upcoming-items/receive/:id",
    element: <ReceiveItems />,
    breadcrumb: "Receive items",
  },
  {
    path: "upcoming-items/receive/waiting-list/:id",
    element: <ReceiveItemsWaitingList />,
    breadcrumb: "waiting list",
  },
  {
    path: "rate-cost",
    element: <RateCost />,
    breadcrumb: "Rate cost",
  },
  {
    path: "rate-cost/create",
    element: <CreateRateCost />,
    breadcrumb: "Rate cost: new",
  },
  {
    path: "rate-cost/edit/:id",
    element: <EditRateCost />,
    breadcrumb: "Rate cost: edit",
  },
  {
    path: "adjust",
    element: <AdjustInventory />,
    breadcrumb: "Inventory Adjustment",
  },
  {
    path: "adjust/new",
    element: <AdjustInventoryForm />,
    breadcrumb: "Inventory Adjustment: new",
  },
  {
    path: "adjust/edit/:id",
    element: <AdjustInventoryEditForm />,
    breadcrumb: "Inventory Adjustment: edit",
    skipIndex: true,
  },
  {
    path: "catalog",
    element: <Catalog />,
    breadcrumb: "Catalog",
  },
  {
    path: "catalog/create",
    element: <CreateCatalog />,
    breadcrumb: "Catalog: new",
  },
  {
    path: "catalog/edit/:id",
    element: <UpdateCatalog />,
    breadcrumb: "Catalog: edit",
  },
  {
    path: "tags",
    element: <Tags />,
    breadcrumb: "Tags",
  },
  {
    path: "inventory-transfer",
    element: <InventoryTransfer />,
    breadcrumb: "Inventory transfer",
  },
  {
    path: "inventory-transfer/create",
    element: <CreateInventoryTransfer />,
    breadcrumb: "Inventory transfer: new",
  },
  {
    path: "inventory-transfer/edit/:id",
    element: <EditInventoryTransfer />,
    breadcrumb: "Inventory transfer: edit",
  },
  {
    path: "custom-attributes",
    element: <CustomAttributes />,
    breadcrumb: "Custom attributes",
  },
  {
    path: "custom-attributes/create",
    element: <CreateCustomAttribute />,
    breadcrumb: "Custom attribute: new",
  },
  {
    path: "custom-attributes/edit/:customAttributeId",
    element: <UpdateCustomAttribute />,
    breadcrumb: "Custom attribute: edit",
  },
  {
    path: "suppliers",
    element: <SuppliersTable />,
    Breadcrumb: "Suppliers",
  },
  {
    path: "suppliers/create",
    element: <CreateSupplier />,
    breadcrumb: "Supplier: new",
  },
  {
    path: "suppliers/edit/:id",
    element: <UpdateSupplier />,
    breadcrumb: "Supplier: edit",
  },
];

export const inventoryRoutesFreestanding = [
  {
    path: "suggested-items/pdf",
    element: <SuggestedItemsPdf />,
  },
  {
    path: "departments/print/:id",
    element: <PrintPdfDepartment />,
  },
  {
    path: "categories/print/:id",
    element: <PrintPdfCategory />,
  },
  {
    path: "seasons/print/:id",
    element: <PrintPdfSeason />,
  },
  {
    path: "upcoming-items/invoice/:id",
    element: <PurchaseOrderInvoice />,
  },
  {
    path: "inventory-transfer/print",
    element: <PrintTransferInventoryPdf />,
  }
];
