import { css } from "#/css/css"
import { styled } from "#/css/jsx"
import dayjs from "dayjs"
import { PieChartCard } from "../common"
import { useState } from "react"
import { DatePickerInput } from "@mantine/dates"
import CalendarIcon from "~icons/ion/calendar-clear-outline"
import { trpc } from "#/trpc"
import GTLoader from "#/components-ng/loader"
import { LoadingOverlay } from "@mantine/core"

export const NotificationUpsellReport = () => {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().startOf("month").toDate(),
		dayjs().endOf("month").toDate(),
	])

	const { data, isLoading } =
		trpc.v2_5.report.notificationUpsellReport.useQuery(
			{
				startDate: dateRange[0]!,
				endDate: dateRange[1]!,
			},
			{
				enabled: dateRange[0] && dateRange[1] ? true : false,
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			},
		)

	return (
		<>
			<styled.div
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				flexDirection={{
					base: "column",
					md: "row",
				}}
			>
				<styled.h2 my="1rem">Notification upsell</styled.h2>
				<DatePickerInput
					name="dateRange"
					type="range"
					onChange={(v) => {
						if (v[0] == null || v[1] == null) return
						setDateRange([v[0], v[1]])
					}}
					numberOfColumns={2}
					popoverProps={{
						zIndex: 9000,
					}}
					w={350}
					required
					icon={<CalendarIcon />}
					defaultValue={dateRange}
				/>
			</styled.div>

			<styled.hr mt="2" mb="2rem" />

			{isLoading ? (
				<LoadingOverlay
					visible={isLoading}
					loader={<GTLoader width={100} height={100} />}
				/>
			) : (
				<styled.div
					display="flex"
					justifyContent="space-between"
					gap="2rem"
					flexDirection={{
						base: "column",
						md: "row",
					}}
				>
					<PieChartCard
						title="Users who purchased after receiving the first email"
						isMoney={false}
						total={data?.totalOrders ?? 0}
						text={
							data?.totalOrdersWithFirstNotification
								? `${((data?.totalOrdersWithFirstNotification / data?.totalOrders) * 100).toFixed(2)}%`
								: "0%"
						}
						data={[
							{
								title: "1st email",
								value: data?.totalOrdersWithFirstNotification ?? 0,
								color: "#4DBAFF",
							},
						]}
						includeLabel={false}
					/>
					<PieChartCard
						title="Users who purchased after receiving the second email"
						isMoney={false}
						total={data?.totalOrders ?? 0}
						text={
							data?.totalOrdersWithSecondNotification
								? `${((data?.totalOrdersWithSecondNotification / data?.totalOrders) * 100).toFixed(2)}%`
								: "0%"
						}
						data={[
							{
								title: "2st email",
								value: data?.totalOrdersWithSecondNotification ?? 0,
								color: "#60C69B",
							},
						]}
						includeLabel={false}
					/>
					<PieChartCard
						title="Users who purchased after being contacted by phone"
						isMoney={false}
						total={data?.totalOrders ?? 0}
						text={
							data?.totalOrdersWithThirdNotification
								? `${((data?.totalOrdersWithThirdNotification / data?.totalOrders) * 100).toFixed(2)}%`
								: "0%"
						}
						data={[
							{
								title: "called",
								value: data?.totalOrdersWithThirdNotification ?? 0,
								color: "#60C69B",
							},
						]}
						includeLabel={false}
					/>
				</styled.div>
			)}
		</>
	)
}
