import { createFormContext } from "#/components-ng/form-context";
import { makeMantineController } from "#/components/Form/v3";
import { useAuth } from "#/context/AuthContext";
import { trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Menu, Modal, ModalProps, Select, Title } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { FormProvider } from "react-hook-form";
import { z } from "zod";

export function SendStockHistoryMenuItem() {
  function handleSendStockHistoryClick() {
    modals.open({
      title: (
        <Title order={3} className="text-base text-slate-700">
          Send stock history through email
        </Title>
      ),
      children: <SendStockHistoryForm />,
    });
  }

  return (
    <Menu.Item onClick={handleSendStockHistoryClick}>
      Send stock history through email
    </Menu.Item>
  );
}

const FormSchema = z.object({
  date: z.date(),
  filialId: z.number(),
});
type FormSchema = z.infer<typeof FormSchema>;

const { C, useForm, Controller } = createFormContext<FormSchema>();

function SendStockHistoryForm() {
  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      date: new Date(),
      filialId: -1,
    },
  });

  const { mutate } = trpc.v2_5.itemSku.getStockHistory.useMutation({
    onSuccess(data) {
      reportUserSuccess({
        title: "Stock history is in the process of being sent",
        message: (
          <span>
            Recipent is <span className="font-medium">{data.sentTo}</span>. This
            can take a few minutes.
          </span>
        ),
      });
    },
  });

  function handleSubmit(values: FormSchema) {
    mutate({
      date: values.date,
      filialId: values.filialId,
    });
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="grid gap-y-4">
          <C
            $as={DatePickerInput}
            name="date"
            label="Date"
            popoverProps={{
              withinPortal: true,
            }}
          />
          <FilialField />
          <Button type="submit" className="justify-self-end px-10">
            Send
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

function FilialField() {
  const { data } = trpc.filial.getAll.useQuery();

  const selectData =
    data?.map((filial) => ({
      label: filial.name,
      value: filial.id.toString(),
    })) ?? [];

  return (
    <Controller
      name="filialId"
      render={(c) => (
        <Select
          data={selectData}
          label="Filial"
          withinPortal
          onChange={(v) => {
            if (v != null) {
              c.field.onChange(Number(v));
            }
          }}
          value={c.field.value.toString()}
        />
      )}
    />
  );
}
