import { FilterOutlineIcon } from "#/components-ng/index.js"
import { useState } from "react"
import dayjs from "dayjs"
import { Filter, FilterKey } from "./$common.js"
import { SalesHistoryTable } from "./table.js"
import { Button, Checkbox, Dropdown } from "@gt/ui"
import { DatePickerInput } from "@mantine/dates"
import CalendarIcon from "~icons/ion/calendar-clear-outline"
import { HStack } from "#/css/jsx"
import { css } from "#/css/css"
import { SearchButton } from "#/components@v2_5/search-button.js"
import { useAuth } from "#/context/AuthContext.js"
import { trpc } from "#/trpc.js"
import { Select } from "@mantine/core"

export default function SalesHistory() {
	const [{ auth }] = useAuth()

	const [filialId, setFilialId] = useState<number | null>(null)
	const [search, setSearch] = useState<string | null>(null)
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().subtract(1, "month").toDate(),
		new Date(),
	])

	const [filters, setFilters] = useState<Record<FilterKey, boolean>>(
		() =>
			Object.fromEntries(
				Object.keys(Filter).map((key) => [
					key,
					key === "COMPLETE" ? true : false,
				]),
			) as any,
	)

	function selectFilter(filter: FilterKey) {
		setFilters((old) => ({
			...old,
			[filter]: !old[filter],
		}))
	}

	const { data: allFilials } = trpc.filial.getAll.useQuery(undefined, {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
    enabled: !auth?.user?.filialId ? true : false,
	})

	return (
		<>
			<HStack justify="space-between" gap={3}>
				<HStack width="100%" gap={3}>
					<SearchButton setQuery={setSearch} />
					<DatePickerInput
						type="range"
						value={dateRange}
						clearable={false}
						popoverProps={{
							withinPortal: false,
							zIndex: 9999,
						}}
						numberOfColumns={2}
						onChange={setDateRange}
						allowSingleDateInRange
						required
						sx={{ width: "100%", maxWidth: "250px" }}
						icon={<CalendarIcon color="#424242" />}
					/>
					<OrderStatusDropdown filters={filters} selectFilter={selectFilter} />
					{!auth?.user?.filialId && (
						<HStack justify="space-between" gap={3} flexWrap="nowrap">
							<Select
								placeholder="Select filial"
								data={
									allFilials?.map((filial) => ({
										value: filial.id.toString(),
										label: filial.name,
									})) ?? []
								}
								value={filialId?.toString() ?? ""}
								onChange={(value) => {
									setFilialId(value ? Number(value) : null)
								}}
								allowDeselect={true}
								w={250}
								clearable
							/>
						</HStack>
					)}
				</HStack>
			</HStack>
			<div className={css({ marginTop: 15 })}>
				<SalesHistoryTable
					filter={
						Object.entries(filters)
							.filter(([, active]) => active)
							.map(([filter]) => filter) as any
					}
					search={search}
					dateRange={dateRange}
					filialId={filialId}
				/>
			</div>
		</>
	)
}

interface OrderStatusDropdownProps {
	filters: Record<FilterKey, boolean>
	selectFilter: (filter: FilterKey) => void
}

const OrderStatusDropdown = (props: OrderStatusDropdownProps) => {
	return (
		<Dropdown.Root>
			<Dropdown.Trigger>
				<Button variant="secondary">
					<FilterOutlineIcon
						className={css({
							marginRight: 2,
						})}
					/>
					Filter by status
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content
				className={css({
					display: "grid",
					gridGap: "0.1rem",
				})}
				loop
			>
				{Object.entries(props.filters).map(([filterKey, isFilterActive], i) => (
					<Dropdown.Item
						key={i}
						className={css({
							display: "flex",
							alignItems: "center",
							gap: 2,
						})}
						onSelect={(e) => {
							e.preventDefault()
							props.selectFilter(filterKey as FilterKey)
						}}
					>
						<Checkbox checked={isFilterActive} />
						<span>{Filter[filterKey]}</span>
					</Dropdown.Item>
				))}
			</Dropdown.Content>
		</Dropdown.Root>
	)
}
